import { useHistory } from "react-router-dom";
import * as classes from "./FAQ.module.css";
import { useMyContext } from "../../contexts/StateHolder";
import { useEffect } from "react";

const FAQ = (props) => {
	const history = useHistory();
	const { language } = useMyContext();

	useEffect(() => {
		if (language?.includes("en")) {
			return history.push(`/en/faq`);
		}
	}, [language, history]);
	return (
		<div className={`${classes.tosContainer} font-300`} style={props?.styles?.tosContaine}>
			<div className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}>
				Usein kysytyt kysymykset (UKK)
			</div>
			<br />
			<hr />
			<br />
			<div className={`${classes.tosTitle} font-400 `}>
				Miten tilaan ja saanko tilausvahvistuksen?
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Asiakas rekisteröityy Koiramessu TV -järjestelmään ja luo sinne henkilökohtaisen tunnuksen
				(sähköposti sekä salasana). Rekisteröitymisen jälkeen asiakas voi valita ja maksaa
				Koiramessu TV -palvelun verkkokaupan maksujärjestelmällä. Maksun jälkeen käyttäjä palautuu
				palveluun joka vahvistaa maksun onnistuneen sekä järjestelmä lähettää asiakkaalle
				tilausvahvistuksen. Jos asiakkaalle ei syystä tai toisesta tule tilausvahvistusta kannattaa
				ensiksi tarkistaa Roskaposti / Junk -email laatikko tai sitten annetussa
				sähköpostiosoitteessa on ollut virhe. Käyttäjä näkee omat tilauksensa sekä yhteystietonsa
				omasta Koiramessu TV:n käyttäjäprofiilista. Mikäli tilausta ei näy omassa profiilissa ja/tai
				sähköpostivahvistusta ei löydy voit ottaa yhteyttä asiakaspalveluumme.
			</p>
			<br />
			<br />
			<div className={`${classes.tosTitle} font-400 `}>Miten voin maksaa tapahtumista? </div>
			<p className={`${classes.tosMainTitle_p} `}>
				Käytämme Paytrail -palvelua, jonka kautta voit maksaa verkkopankkitunnuksilla tai
				maksukorteilla (credit/ debit). Käytettävissä ovat seuraavat maksutavat: Osuuspankki,
				Nordea, Danske Bank, Oma Säästöpankki, Säästöpankki, Aktia, Paikallisosuuspankit, S-Pankki,
				Handelsbanken, Ålandsbanken, MobilePay, Masterpass, Visa-, Visa Debit-, Visa Electron- ja
				MasterCard- kortit.
			</p>
			<br />
			<br />
			<div className={`${classes.tosTitle} font-400 `}>
				Maksu tapahtuma keskeytyy / maksu ei onnistu, mitä voin tehdä?
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Mikäli maksu ei jostain syystä onnistu, niin voit yrittää uudestaan. Mikäli virheilmoitus
				tulee joko luottokortti- tai verkkopankkimaksun yhteydessä, kannattaa olla yhteydessä
				pankkiisi tai kortin myöntäneeseen yritykseen. Mikäli maksu luottokortti tai
				verkkopankkimaksu onnistuu, mutta tilaus ei tule omaan profiiliisi, niin kannattaa olla
				yhteydessä asiakaspalveluumme
			</p>
			<br />
			<br />
			<div className={`${classes.tosTitle} font-400 `}>
				Kuinka kauan aikaisemmin on maksettava tuntimaksu, jotta se ehtii tulla vahvistetuksi ja
				näen tapahtuman?
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Maksujärjestelmä lähettää ilmoituksen Koiramessu TV:seen heti, kun maksu on hyväksytty eli
				lähetystä katsomaan pääsee välittömästi maksun suorituksen jälkeen. Lisäksi maksulla pääsee
				katsomaan lähetyksen tallennetta, joten jos haluat ostaa katseluoikeuden päättyneen
				tapahtuman tallenteeseen, niin se on mahdollista myös.
			</p>
			<br />
			<div className={`${classes.tosTitle} font-400 `}>Koska tilaukseni alkaa?</div>
			<br />
			<p className={`${classes.tosMainTitle_p} `}>
				Tilauksesi alkaa sillä hetkellä, kun maksusi on hyväksytty. Tilaus on voimassa valitsemasi
				paketin pituuden mukaan maksuhetkestä 6kk:tta.
			</p>
			<br />
			<br />
			<div className={`${classes.tosTitle} font-400`}>
				Millä päätelaitteilla voin katsoa sisältöjä?
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Koiramessu TV -palvelu toimii kaikissa uusimmissa selaimissa eri desktop-, mobiili- ja
				tablet-päätelaitteissa.
			</p>
			<br />
			<p className={`${classes.tosTitle} font-400`}>Toimiiko palvelu Chromecastilla?</p>
			<br />
			<p className={`${classes.tosMainTitle_p} `}>
				Koiramessu TV:n käyttämä videosoitin tukee myös Chromecast / Googlecast -ominaisuutta
				joissakin päätelaitteissa, mutta päätelaitteissa on eroja ja emme pysty tällä hetkellä
				testaamaan niitä kaikkia ja antamaan tukea sen toiminnalle.
			</p>
			<br />
			<div className={`${classes.tosTitle} font-400`}>
				Live -lähetys on alkanut, mutta videokuva ei tule ruutuun, mitä voin tehdä?
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Mikäli kaiken pitäisi olla kunnossa (sekä ohjelman aikataulun mukaisesti käynnissä), mutta
				et näe videokuvaa, niin päivitä selaimesi (sivun päivitys/ refresh painikkeesta).
			</p>
			<br />
			<br />
			<div className={`${classes.tosTitle} font-400`}>Video pätkii, mitä voin tehdä? </div>
			<p className={`${classes.tosMainTitle_p} `}>
				Videon pätkiminen voi johtua monesta syystä. Sujuva katselu vaatii vain noin 3 Mbps
				nettiyhteyden, mutta erityisesti poikkeusaikoina voi nettiyhteydet eri puolella olla niin
				suuressa käytössä, että yhteys pätkii. Mikäli saatavilla, suosittelemme silloin kokeilemaan
				toista yhteyttä joko mobiili-nettiä tai kodin Wifi-yhteyttä. Myös selaimen, päätelaitteen
				tai (kodin laajakaista yhteyttä käytettäessä nettimodeemin) käynnistäminen uudelleen voi
				auttaa.
			</p>
			<br />
			<div className={`${classes.tosTitle} font-400`}>
				Minulla on ongelma kirjautumisen/rekisteröitymisen kanssa, mitä voin tehdä?{" "}
			</div>
			<br />
			<p className={`${classes.tosMainTitle_p} `}>
				Mikäli sinulla on ongelmia rekisteröitymisen/ kirjautumisen kanssa, niin kokeile toista
				Internet-selainta (esim. Mozilla Firefox, Chrome tai Windows Explorer).
			</p>{" "}
			<br />
			<br />
			<div className={`${classes.tosTitle} font-400`}>
				Näenkö Live -lähetyksien tallenteita Koiramessu TV:stä?
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Kyllä näet! Live -lähetykset tallennetaan videokirjastoon.
			</p>
			<br />
			<div className={`${classes.tosTitle} font-400`}>
				Mitä teen, kun en saa yksin selvitettyä asiaa?
			</div>
			<p className={`${classes.tosMainTitle_p} `}>Voit ottaa yhteyttä asiakaspalveluumme:</p>
			<br />
			<p className={`${classes.tosMainTitle_p} `}>koiramessutv@kennelliitto.fi</p>
			<br />
			<p className={`${classes.tosMainTitle_p} `}>Palvelemme MA-PE välillä 8:00 - 16:00.</p>
			<br />
		</div>
	);
};

export default FAQ;
