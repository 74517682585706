import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { getEventDescription, getEventTitle, momentDate } from "../../../scripts/utils";
import Header from "../../../Shared/Header/Header";
import * as classes from "./UpcomingEvent.module.css";
import { useMyContext } from "../../../contexts/StateHolder";

const CompletedEventsWithoutRecording = ({ singleEvent, defaultLanguage }) => {
	const { t } = useTranslation();

	const { language } = useMyContext();

	return (
		<div className={`${classes.EventTertairy} smallcasebold`}>
			<div className={classes.EventPrimary}>
				<Header
					extraClassname={true}
					title={getEventTitle(singleEvent, language)}
					styles={{ color: "black" }}
					color={"#161b55"}
					admin={true}
				/>

				<div className={classes.EventTimeDescription}>
					<div className={`${classes.EventPrimary_date} font-300`}>
						<div>
							<FontAwesomeIcon icon="calendar" />
						</div>

						<div>
							{momentDate(
								singleEvent.startTimePublic ? singleEvent?.startTimePublic : singleEvent?.startTime,
								language
							)}
						</div>
					</div>
					<div className={`${classes.EventPrimary_time} font-300`}>
						<div>
							{" "}
							<FontAwesomeIcon icon="clock" />
						</div>
						<div>
							{moment(
								singleEvent.startTimePublic ? singleEvent?.startTimePublic : singleEvent?.startTime
							)
								.locale("fi")
								.format("LT")}
						</div>{" "}
					</div>
				</div>

				<div
					className={classes.EventPrimary_description}
					dangerouslySetInnerHTML={{
						__html: getEventDescription(singleEvent, language),
					}}
					style={{ paddingBottom: "30px" }}
				></div>

				<div className={`${classes.EventPrimary_countdown} font-500`}>
					{t("eventsCategory.eventsEnded")}
				</div>
			</div>
		</div>
	);
};

export default CompletedEventsWithoutRecording;
