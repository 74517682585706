import * as classes from "./TOS.module.css";
import { getImageByKey } from "../../scripts/getImageByKey";
import { useMyContext } from "../../contexts/StateHolder";
import { useHistory } from "react-router-dom";
import settings from "../../config_settings.json";
import { useEffect } from "react";

const TOS = (props) => {
	const { language } = useMyContext();
	const history = useHistory();

	useEffect(() => {
		if (language?.includes("en")) {
			return history.push(`/en/${settings.routes.tos}`);
		}
	}, [language, history]);
	return (
		<div className={`${classes.tosContainer} font-300`} style={props?.styles?.tosContaine}>
			<div className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}>
				Yleiset toimitus- ja sopimusehdot
			</div>
			<hr />
			<p className={`${classes.tosMainTitle_p} `}>
				{" "}
				Toimitusehdot ovat voimassa 28.11.2022 alkaen.
			</p>
			<div className={`${classes.tosTitle} font-400 `}>Yleistä </div>
			<p className={`${classes.tosMainTitle_p} `}>
				Koiramessu TV -portaali on Suomen Kennelliitto - Finska Kennelklubben ry.:n (myöhemmin
				”Kennelliitto”) ylläpitämä ja hallinnoima live-lähetys sivusto osoitteessa:
				www.koiramessutv.fi. Sivuston teknisestä ylläpidosta ja kehityksestä vastaa Icareus Oy.
			</p>
			<p className={`${classes.tosMainTitle_p} `}>
				Palvelu ja siellä näkyvät live-materiaalit, kuvat, tekstit ja logot ovat Kennelliiton tai
				sen yhteistyökumppanien omaisuutta, ja niitä ei saa ilman edellä mainittujen tahojen
				yksilöityä lupaa tallentaa, jakaa, levittää tai näyttää yksityisesti ja/ tai julkisesti.
			</p>
			<div className={`${classes.tosTitle} font-400 `}>
				Toimitusehdot ja Live-lähetysten katsominen{" "}
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Live-lähetyksiä voi seurata Koiramessu TV:stä erilaisia päätelaitteita käyttämällä.
				Lähetysten seuranta ja maksaminen tapahtuu sivuston kautta.
			</p>
			<p className={`${classes.tosMainTitle_p} `}>
				Yhteystiedot: Suomen Kennelliitto - Finska Kennelklubben ry. Valimotie 17-19, 00380 Helsinki{" "}
			</p>
			<div className={`${classes.tosTitle} font-400 `}>Tilaus sekä tilausvahvistus</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Asiakas rekisteröityy Koiramessu TV -järjestelmään ja luo sinne henkilökohtaisen profiilin
				(sähköposti sekä salasana). Tilattavat tuotteet valitaan kotisivuilla lisäämällä ne
				ostoskoriin. Tilaus lähetetään maksamalla ostoskorin sisältö verkkokaupan kassatoiminnossa.
				Tehdessäsi tilauksen hyväksyt nämä toimitusehdot, tuotteiden hinnat sekä toimituskulut.
				Mikäli tilaushetkellä annetaan sähköpostiosoite, tilauksesta lähetetään tilausvahvistus
				sähköpostitse. Tilausvahvistuksesta ilmenevät tilatut tuotteet sekä hinta.
			</p>
			<p className={`${classes.tosMainTitle_p} `}>
				Jos asiakkaalle ei syystä tai toisesta tule tilausvahvistusta, tilaus ei todennäköisesti ole
				onnistunut, tai annetussa sähköpostiosoitteessa on ollut virhe. Kehotamme tällaisessa
				tilanteessa ottamaan viivytyksettä yhteyttä asiakaspalveluumme.
			</p>
			<div className={`${classes.tosTitle} font-400 `}>Sopimuksen syntyminen sekä maksaminen</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Sopimus syntyy, kun asiakas on kirjautunut järjestelmään ja maksanut valitsemansa paketin.
				Kauppa syntyy verkkokaupan asiakkaan ja verkkokaupan välille. Verkkokaupan vastuulla ovat
				kaikki kauppaan liittyvät velvoitteet.
			</p>
			<div className={`${classes.tosTitle} font-400 `}>Maksaminen ja maksutavat</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Maksunvälityspalvelun toteuttajana ja maksupalveluntarjoajana toimii Paytrail Oyj
				(2122839-7), , joka on rekisteröity Finanssivalvonnan ylläpitämään maksulaitosrekisteriin,
				yhteistyössä suomalaisten pankkien ja luottolaitosten kanssa. Paytrail Oyj näkyy maksun
				saajana tiliotteella tai korttilaskulla ja välittää maksun kauppiaalle. Paytrail Oyj:llä on
				maksulaitoksen toimilupa. Reklamaatiotapauksissa pyydämme ottamaan ensisijaisesti yhteyttä
				tuotteen toimittajaan.
			</p>
			<p className={`${classes.tosMainTitle_p} `}>
				Paytrail Oyj, business ID: 2122839-7 <br />
				Innova 2<br />
				Lutakonaukio 7<br />
				40100 Jyväskylä
				<br />
			</p>
			<p className={`${classes.tosMainTitle_p} `}>paytrail.com/kuluttaja/tietoa-maksamisesta</p>
			<p className={`${classes.tosMainTitle_p} `}>
				Maksutapa valitaan tilauksen tekemisen yhteydessä.
			</p>
			<p className={`${classes.tosMainTitle_p} `}>
				Paytrail-palvelun kautta voit maksaa verkkopankkitunnuksilla ja maksukorteilla (credit/
				debit). Käytettävissä ovat seuraavat maksutavat: Osuuspankki, Nordea, Danske Bank, Oma
				Säästöpankki, Säästöpankki, Aktia, Paikallisosuuspankit, S-Pankki, Handelsbanken,
				Ålandsbanken, MobilePay, Masterpass, Visa-, Visa Debit-, Visa Electron-, MasterCard--
				kortit.{" "}
			</p>
			<img src={getImageByKey("paymentMethodPaytrail")} alt="Paytrail Payment" />
			<p className={`${classes.tosMainTitle_p} `}>
				Kaikki maksutavat ovat erittäin turvallisia, sillä kaikki maksutapahtumaa koskevat tiedot
				välitetään salattua yhteyttä käyttäen niin ettei kukaan ulkopuolinen taho näe
				maksutapahtuman tietoja. Verkkomaksut ja luottokorttimaksut maksetaan pankkien tai
				Paytrailin sivujen kautta. Kennelliitto ei tallenna tai saa tietoonsa asiakkaiden
				tilitietoja. Luottokorttimaksujen tiedot tallentavat Paytrail. Luottokorttimaksujen
				varmentamiseen käytetään "Verified by Visa" tai "MasterCard SecureCode" -järjestelmiä.
				Luottokorttiveloitus hyväksytään ja korttia veloitetaan tämän vuoksi tilaushetkellä.{" "}
			</p>
			<div className={`${classes.tosTitle} font-400`}>Hinnat</div>
			<p className={`${classes.tosMainTitle_p} `}>Tuotteiden hinnat sisältävät arvonlisäveron.</p>
			<p className={`${classes.tosMainTitle_p} `}>
				Tuote myydään tilaamishetkellä verkkokaupan sivulla ilmoitettavalla hinnalla. Kennelliitto
				pidättää oikeuden olla myymättä tuotetta hinnalla, joka on esim. teknisestä tai
				inhimillisestä syystä päivittynyt verkkokauppaan olennaisesti väärin. Tällaisessa
				tapauksessa sovelletaan yleisiä sopimusoikeudellisia periaatteita. Näissä tapauksissa
				Kennelliitto on yhteydessä asiakkaaseen ja kaupan purkaminen on mahdollista.
			</p>
			<p className={`${classes.tosTitle} font-400`}>Käyttöaika </p>
			<p className={`${classes.tosMainTitle_p} `}>
				Asiakkaan ostama Koiramessu TV:n palvelupaketti tulee asiakkaan käytettäväksi välittömästi,
				kun verkkokauppaan on tullut vahvistus maksujärjestelmältä maksun hyväksymisestä.
				Live-lähetyksien lisäksi tapahtuman tilanneella käyttäjällä on mahdollisuus katsoa lähetys
				myös tallenteena, joka on saatavilla vähintää 6kk tilauksesta.
			</p>
			<div className={`${classes.tosTitle} font-400`}>Tilauksen peruutus</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Yksittäisillä lähetys -tilauksilla ei ole palautusoikeutta.
			</p>
			<div className={`${classes.tosTitle} font-400`}>Reklamaatiot </div>
			<p className={`${classes.tosMainTitle_p} `}>
				Verkkokaupalla on lakisääteinen virhevastuu myydyistä tuotteista. Palvelun
				toimimattomuudesta tai muista palvelussa ilmenneistä virheistä tulee reklamoida viipymättä
				asiakaspalveluumme. Pyrimme ensisijaisesti aina korjaamaan virheen ja auttamaan asiakasta
				palvelun käytön suhteen.
			</p>
			<div className={`${classes.tosTitle} font-400`}>Yrityksen vastuu ja vastuun rajoitukset </div>
			<p className={`${classes.tosMainTitle_p} `}>
				Kennelliitto ei vastaa yrityksen vaikutusmahdollisuuksien ulkopuolella ilmenevistä
				ongelmista (esim. yksittäisen kuluttajan Internet-operaattorista johtuvista Internetyhteyden
				pätkimisistä tai laitteiston yhteensopivuusongelmista).
			</p>{" "}
			<p className={`${classes.tosMainTitle_p} `}>
				Kennelliitto pidättää oikeuden toimitusehtojen ja hintojen sekä aikataulujen ja
				ohjaajamuutoksiin.{" "}
			</p>{" "}
			<p className={`${classes.tosMainTitle_p} `}>
				Kennelliitto pidättää oikeuden muuttaa toimitus- ja sopimusehtojaan ilmoituksetta.{" "}
			</p>{" "}
			<p className={`${classes.tosMainTitle_p} `}>
				Kennelliitto toimii hyvän kauppiastavan mukaisesti ja noudattaa toiminnassaan Suomen lakia.
				Asiakkaan ja Kennelliitto:n väliset riitatapaukset ratkaistaan ensisijaisesti
				neuvottelemalla ja jos sopimukseen ei päästä, niin Helsingin käräjäoikeudessa.{" "}
			</p>{" "}
			<p className={`${classes.tosMainTitle_p} `}>
				Kaikissa toimintaan liittyvissä kysymyksissä ja palautteissa pyydetään asiakkaita ottamaan
				yhteyttä asiakaspalveluumme.{" "}
			</p>{" "}
			<div className={`${classes.tosTitle} font-400`}>Force majeure</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Kennelliitto ei ole vastuussa palvelun toimimattomuudesta, viivästyksestä tai estymisestä,
				mikäli syy tähän on Kennelliitto:n vaikutusmahdollisuuksien ulkopuolella oleva seikka, kuten
				tavarantoimitusten katkokset, estävät sääolosuhteet, luonnonmullistus, sota, onnettomuus,
				epidemia, tuonti- tai vientikielto, viranomaisten päätös, liikenteen tai energian jakelun
				häiriintyminen tai estyminen, työtaistelutoimenpide tai muu vastaava Kennelliitto:n tai
				aiemman myyntiportaan toimintaa estävä seikka.
			</p>
			<div className={`${classes.tosTitle} font-400`}>Asiakasrekisteri ja rekisteriseloste </div>
			<p className={`${classes.tosMainTitle_p} `}>
				Palvelun käyttö vaatii asiakkaalta kirjautumisen Koiramessu TV-järjestelmään.
				Taustajärjestelmään tallennetaan tuolloin asiakkaan sähköposti sekä omavalintainen salasana.
			</p>
			<p className={`${classes.tosMainTitle_p} `}>
				Asiakastiedot ovat luottamuksellisia eikä niitä luovuteta Kennelliiton ulkopuolisille
				osapuolille, pois lukien mahdolliset maksujärjestelmäpalvelut tai niihin liittyvät
				kysymykset. Sähköpostitiedotusta ja mainontaa voidaan tehdä rekisteröityneille asiakkaille
				palvelussa olevista tai sinne tulevista tuotteista.
			</p>
			<p className={`${classes.tosMainTitle_p} `}>
				Palvelussamme saatetaan käyttää evästeitä sivuston tilatietojen tallentamiseen.
				Tallennettavia tilatietoja ovat mm. sisäänkirjautumistiedot ja valitun palvelun sisältö.
				Evästetiedostoihin ei tallenneta asiakkaan henkilökohtaisia tietoja.
			</p>
			<div className={`${classes.tosTitle} font-400`}>Rekisteriseloste </div>
			<p className={`${classes.tosMainTitle_p} `}>Yhteystiedot: </p>
			<p className={`${classes.tosMainTitle_p} `}>Suomen Kennelliitto - Finska Kennelklubben ry.</p>
			<p className={`${classes.tosMainTitle_p} `}>Valimotie 17-19, 00380 Helsinki </p>
		</div>
	);
};

export default TOS;
