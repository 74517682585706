import * as classes from "./TOS.module.css";
import { getImageByKey } from "../../scripts/getImageByKey";
import { useMyContext } from "../../contexts/StateHolder";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

import settings from "../../config_settings.json";

const TOSEn = (props) => {
	const { language } = useMyContext();

	const history = useHistory();

	useEffect(() => {
		if (language?.includes("fi")) {
			return history.push(`/${settings.routes.tos}`);
		}
	}, [language, history]);
	return (
		<div className={`${classes.tosContainer} font-300`} style={props?.styles?.tosContaine}>
			<div className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}>
				General terms and conditions of delivery and contract{" "}
			</div>
			<hr />
			<p className={`${classes.tosMainTitle_p} `}>
				{" "}
				The terms of delivery are valid from 28 November 2022.
			</p>
			<div className={`${classes.tosTitle} font-400 `}>General </div>
			<p className={`${classes.tosMainTitle_p} `}>
				The Koiramessu TV portal is a live broadcast site maintained and managed by the Suomen
				Kennelliitto - Finska Kennelklubben ry. (later “Suomen Kennelliitto” or "Kennelliitto ") at
				the address: www.koiramessutv.fi. Icareus Oy is responsible for the technical maintenance
				and development of the website.
			</p>
			<p className={`${classes.tosMainTitle_p} `}>
				The service and the live materials, images, texts and logos displayed there are the property
				of the Kennelliitto or its partners, and they may not be recorded, distributed, aggregated
				or displayed privately and/or publicly without the specific permission of the aforementioned
				parties.
			</p>
			<div className={`${classes.tosTitle} font-400 `}>
				Terms of delivery and watching Live broadcasts
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Live broadcasts can be followed from Koiramessu TV using various terminals. Watching the
				broadcasts and making the payment is done through the website.
			</p>
			<p className={`${classes.tosMainTitle_p} `}>
				Contact information: Suomen Kennelliitto - Finska Kennelklubben ry. Valimotie 17-19, 00380
				Helsinki{" "}
			</p>
			<div className={`${classes.tosTitle} font-400 `}>Order and order confirmation</div>
			<p className={`${classes.tosMainTitle_p} `}>
				The customer registers in the KoiramessuTV system and creates a personal profile there
				(email and password). The products to be ordered are selected on the homepage by adding them
				to the shopping cart. The order is sent by paying for the contents of the shopping cart in
				the checkout function of the online store. By placing an order, you accept these terms of
				delivery, product prices and delivery costs. If an e-mail address is given at the time of
				ordering, an order confirmation will be sent by e-mail. The order confirmation shows the
				ordered products and the price.
			</p>
			<p className={`${classes.tosMainTitle_p} `}>
				If, for one reason or another, the customer does not receive an order confirmation, the
				order was probably not successful, or there was an error in the e-mail address given. In
				such a situation, we urge you to contact our customer service without delay.
			</p>
			<div className={`${classes.tosTitle} font-400 `}>Formation of the contract and payment</div>
			<p className={`${classes.tosMainTitle_p} `}>
				The contract is created when the customer has logged into the system and paid for the
				package he selected. The transaction is created between the customer and the online store.
				The online store is responsible for all obligations related to the store.
			</p>
			<div className={`${classes.tosTitle} font-400 `}>Payment and payment methods</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Paytrail Oyj (2122839-7), which is registered in the register of payment institutions
				maintained by the Financial Supervisory Authority, in cooperation with Finnish banks and
				credit institutions, acts as the implementation and payment service provider of the payment
				brokerage service. Paytrail Oyj appears as the recipient of the payment on the bank
				statement or card invoice and forwards the payment to the merchant. Paytrail Oyj has a
				payment institution license. In cases of complaints, we ask that you first contact the
				supplier of the product.
			</p>
			<p className={`${classes.tosMainTitle_p} `}>
				Paytrail Oyj, business ID: 2122839-7 <br />
				Innova 2<br />
				Lutakonaukio 7<br />
				40100 Jyväskylä
				<br />
			</p>
			<p className={`${classes.tosMainTitle_p} `}>paytrail.com/kuluttaja/tietoa- about paying</p>
			<p className={`${classes.tosMainTitle_p} `}>
				The payment method is selected when placing the order.{" "}
			</p>
			<p className={`${classes.tosMainTitle_p} `}>
				Through the Paytrail service, you can pay with online bank credentials and payment cards
				(credit/debit). The following payment methods are available: Osuuspankki, Nordea, Danske
				Bank, Oma Säästöpankki, Säästöpankki, Aktia, Paikallisosuuspankkit, S-Pankki, Handelsbanken,
				Ålandsbanken, MobilePay, Masterpass, Visa, Visa Debit, Visa Electron, MasterCard cards.
			</p>
			<img src={getImageByKey("paymentMethodPaytrail")} alt="Paytrail Payment" />
			<p className={`${classes.tosMainTitle_p} `}>
				All payment methods are very secure, as all information about the payment transaction is
				transmitted using an encrypted connection so that no outside party can see the information
				of the payment transaction. Online payments and credit card payments are paid through banks
				or Paytrail's website. Suomen Kennelliitto does not store or gain access to customer account
				information. Credit card payment information is stored by Paytrail. "Verified by Visa" or
				"MasterCard SecureCode" systems are used to verify credit card payments. Credit card
				debiting is accepted and the card will therefore be charged at the time of ordering.
			</p>
			<div className={`${classes.tosTitle} font-400`}>Prices</div>
			<p className={`${classes.tosMainTitle_p} `}>Product prices include VAT.</p>
			<p className={`${classes.tosMainTitle_p} `}>
				The product is sold at the price indicated on the online store page at the time of ordering.
				Suomen Kennelliitto reserves the right not to sell the product at a price that, for example
				due to technical or human reasons, has been substantially incorrectly updated in the online
				store. In such a case, general contract law principles apply. In these cases, the Suomen
				Kennelliitto is in contact with the customer and cancellation of the deal is possible.
			</p>
			<p className={`${classes.tosTitle} font-400`}>Length of the Service </p>
			<p className={`${classes.tosMainTitle_p} `}>
				The service package of Koiramessu TV purchased by the customer becomes available to the
				customer immediately, when the online store has received confirmation from the payment
				system that the payment has been accepted. In addition to live broadcasts, the user who
				subscribed to the event has the option to watch the broadcast as a recording, which is
				available for at least 6 months after the subscription.
			</p>
			<div className={`${classes.tosTitle} font-400`}>
				Unsubscribing or cancellation of the Service
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Individual shipment orders do not have the right of return.
			</p>
			<div className={`${classes.tosTitle} font-400`}>Complaints </div>
			<p className={`${classes.tosMainTitle_p} `}>
				The online store has statutory error liability for the products sold. Complaints about
				non-functioning of the service or other errors occurring in the service must be made to our
				customer service immediately. We primarily try to always correct the error and help the
				customer regarding the use of the service.
			</p>
			<div className={`${classes.tosTitle} font-400`}>
				Suomen Kennelliitto's liability and limitations of liability{" "}
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Kennelliitto is not responsible for problems that arise outside the company's influence
				(e.g. interruptions in the Internet connection or hardware compatibility problems caused by
				an individual consumer's Internet operator).
			</p>{" "}
			<p className={`${classes.tosMainTitle_p} `}>
				Suomen Kennelliitto reserves the right to make changes to delivery conditions and prices as
				well as schedules and instructors.
			</p>{" "}
			<p className={`${classes.tosMainTitle_p} `}>
				Kennelliitto reserves the right to change its terms of delivery and contract without notice.
			</p>{" "}
			<p className={`${classes.tosMainTitle_p} `}>
				Suomen Kennelliitto operates in accordance with good business practices and complies with
				Finnish law in its operations. Disputes between the customer and the Suomen Kennelliitto are
				primarily resolved through negotiation, and if no agreement can be reached, then in the
				Helsinki district court.
			</p>{" "}
			<p className={`${classes.tosMainTitle_p} `}>
				For all activity-related questions and feedback, customers are asked to contact our customer
				service.
			</p>{" "}
			<div className={`${classes.tosTitle} font-400`}>Force majeure</div>
			<p className={`${classes.tosMainTitle_p} `}>
				Suomen Kennelliitto is not responsible for non-functioning, delay or obstruction of the
				service, if the reason for this is a circumstance beyond the control of the Suomen
				Kennelliitto, such as interruptions in goods deliveries, impeding weather conditions,
				natural disasters, war, accidents, epidemics, import or export ban, decision of the
				authorities, traffic or energy distribution disruption or obstruction, industrial action or
				other similar circumstance preventing the operation of the Kennelliitto or the previous
				sales ladder.
			</p>
			<div className={`${classes.tosTitle} font-400`}>
				Customer register and Register statement{" "}
			</div>
			<p className={`${classes.tosMainTitle_p} `}>
				The use of the service requires the customer to log in to the Koiramessu TV system. At that
				time, the customer's e-mail and self-selected password are stored in the background system.
			</p>
			<p className={`${classes.tosMainTitle_p} `}>
				Customer information is confidential and will not be disclosed to parties outside the Suomen
				Kennelliitto, excluding possible payment system services or related questions. E-mail
				information and advertising can be sent to registered customers about the products that are
				in the service or that will be there.
			</p>
			<p className={`${classes.tosMainTitle_p} `}>
				Our service may use cookies to save site status information. Status data to be saved are
				e.g. login information and the content of the selected service. The customer's personal
				information is not stored in the cookie files.
			</p>
			<div className={`${classes.tosTitle} font-400`}>Register statement </div>
			<p className={`${classes.tosMainTitle_p} `}>Contact information: </p>
			<p className={`${classes.tosMainTitle_p} `}>Suomen Kennelliitto - Finska Kennelklubben ry.</p>
			<p className={`${classes.tosMainTitle_p} `}>Valimotie 17-19, 00380 Helsinki </p>
		</div>
	);
};

export default TOSEn;
