import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../Shared/Button/Button";

import * as settings from "../../config_settings.json";
import { useTranslation } from "react-i18next";

export default function ResetPasswordTokenInvalid() {
    const { t } = useTranslation();

    return (
        <div
            className="font-500"
            style={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                marginTop: "50px",
                gap: "20px",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div className="font-700" style={{ textAlign: "center", color: "#ab0303" }}>
                {t("resetPassword.invalidLink")}
            </div>

            <FontAwesomeIcon icon="times-circle" className="font-800" style={{ display: "flex", alignSelf: "center", color: "#ab0303" }} />
            <div> {t("resetPassword.invalidTokenMsg1")}</div>

            <div> {t("resetPassword.invalidTokenMsg2")}</div>

            <Button to={`/${settings.routes.requestResetPassword}`}>{t("resetPassword.requestResetPasswordLink")}</Button>
        </div>
    );
}
