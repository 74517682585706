import { NavLink } from "react-router-dom";
import { getImageByKey } from "../../scripts/getImageByKey";
import { useTranslation } from "react-i18next";
import * as classes from "./NavBar.module.css";
import { CgMenu, CgClose } from "react-icons/cg";

import { useMyContext } from "../../contexts/StateHolder";

const NavBar = ({
    links,

    styles,
    linksMobileSearch,
    linksMobileLanguageSelect,
}) => {
    const { t } = useTranslation();

    const { isMenu, setisMenu, closeHamMenu, isResponsiveclose, setResponsiveclose } = useMyContext();
    // for main navigation mobile menu
    const toggleClass = () => {
        setisMenu(isMenu === false ? true : false);
        setResponsiveclose(isResponsiveclose === false ? true : false);
    };

    let boxClass = ["main-menu menu-right menuq1 scale-up-center "];
    if (isMenu) {
        boxClass.push("menuq2 ");
    } else {
        boxClass.push("slide-out-top");
    }

    return (
        <div className={classes.navBar} style={styles?.navBar}>
            <NavLink to="/" exact activeClassName="no-class">
                <img
                    className="navBarSiteLogo navBarSiteLogoBig"
                    src={getImageByKey("organizationLogo")}
                    title={t("Back to Home")}
                    alt="SiteName"
                    style={styles?.navBar}
                    onClick={closeHamMenu}
                ></img>
            </NavLink>

            <nav className={`${classes.navBarRow} main-nav `}>
                <div className="action">
                    {isResponsiveclose === true ? (
                        <>
                            <span className="menubar__button " style={{ display: "none" }} onClick={toggleClass}>
                                {" "}
                                <CgClose />
                            </span>
                        </>
                    ) : (
                        <>
                            <span className="menubar__button" style={{ display: "none" }} onClick={toggleClass}>
                                {" "}
                                <CgMenu />
                            </span>
                        </>
                    )}
                </div>
                <ul className={` ${classes.navBarRow_primary} ${boxClass.join(" ")}`}>
                    {links}

                    <div className="navBarSiteLogoMobile languageSelectMobile">{linksMobileLanguageSelect}</div>
                </ul>

                <div className={"navBarSiteLogoMobile"}>
                    <NavLink to="/" exact activeClassName="no-class">
                        <img
                            src={getImageByKey("organizationLogo")}
                            title={t("navBar.backToHome")}
                            alt="SiteName"
                            style={styles?.navBar}
                            onClick={closeHamMenu}
                            className="navBarSiteLogoMobile-img"
                        ></img>
                    </NavLink>
                </div>

                <div className={"navBarSiteLogoMobile"}>{linksMobileSearch}</div>
            </nav>
        </div>
    );
};

export default NavBar;
