import React from "react";
import * as classes from "../../EditorChoice/EditorChoice.module.css";
import EventsCategoryItems from "../EventsCategory/EventsCategoryItems";

export default function EventsEditorChoice({ categoryId, limit, organizationId }) {
    return (
        <div className={classes.EditorChoice} style={{ margin: "30px 0" }}>
            <EventsCategoryItems organizationId={organizationId} categoryId={categoryId} limit={limit} heading="Koiramessut" />
        </div>
    );
}
