import React from "react";
import { getImageByKey } from "../../scripts/getImageByKey";
import * as classes from "../ContactUs/ContactUs.module.css";
import { useTranslation } from "react-i18next";

export default function AboutUs() {
	// Setup translate function
	const { t } = useTranslation();

	return (
		<div className={classes.ContactUs}>
			<div className={classes.ContactUsPrimary}>
				<div className={classes.ContactUs_image}>
					<img src={getImageByKey("aboutus")} alt="Contact Us" />
				</div>
				<div className={classes.ContactUs_description}>
					<div className={classes.ContactUs_description_Primary}>
						<h4 className="font-700">{t("footer.aboutus")}</h4>
						<div className={classes.ContactUs_description_address}>
							<div
								className={`${classes.ContactUs_description_address_info} font-400`}
							>
								{t("aboutUs.text1")}
							</div>
						</div>
						<div className={classes.ContactUs_description_phone}>
							<div>{t("aboutUs.text2")}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
