import { useHistory } from "react-router-dom";
import { useMyContext } from "../../contexts/StateHolder";
import * as classes from "./PrivacyPolicy.module.css";
import { useEffect } from "react";

import settings from "../../config_settings.json";

const PrivacyPolicyEn = (props) => {
	const { language } = useMyContext();

	const history = useHistory();

	useEffect(() => {
		if (language?.includes("fi")) {
			return history.push(`/${settings.routes.privacyPolicy}`);
		}
	}, [language, history]);
	return (
		<div className={`${classes.tosContainer} font-300`}>
			<ol>
				<div className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}>
					Privacy Terms Statement
				</div>
				<hr />
				<div className={`${classes.tosMainTitle_p} `}>
					{" "}
					Koiramessu TV respects and protects the privacy of the people who visit its website, its
					customers and the people working in stakeholder group work. On this page you will find our
					privacy policy, terms of use of our pages and register statements.
				</div>
				<div className={`${classes.tosTitle} font-500 `}>Our privacy policy</div>
				<div className={`${classes.tosMainTitle_p} `}>
					We only collect and process necessary information and delete outdated information.{" "}
				</div>{" "}
				<div className={`${classes.tosMainTitle_p} `}>
					We use personal data in communication, customer service and delivery of services{" "}
				</div>{" "}
				<div className={`${classes.tosMainTitle_p} `}>
					Personal data is processed centrally at the office of the Suomen Kennelliitto- Finska
					Kennelklubben ry. in Espoo.
				</div>{" "}
				<div className={`${classes.tosMainTitle_p} `}>
					Personal data is also used to target authorized marketing and advertising.{" "}
				</div>{" "}
				<div className={`${classes.tosMainTitle_p} `}>
					In principle, we do not hand over personal data outside the association.{" "}
				</div>{" "}
				<div className={`${classes.tosMainTitle_p} `}>
					You can find more detailed information about the processing and use of personal data and
					your own rights in the personal data register statements below.
				</div>{" "}
				<div className={`${classes.tosTitle} font-500 `}>Cookies</div>
				<div className={`${classes.tosMainTitle_p} `}>
					When you visit the KoiramessuTV website, your web browser automatically saves cookies on
					your device's hard drive. A cookie can contain text, numbers, dates, location information,
					and other data, but cookies do not store any personal information. A cookie is also not an
					application, and it cannot allow viruses or other malware to enter your computer. Cookies
					do not harm your computer in any way.
				</div>{" "}
				<div className={`${classes.tosMainTitle_p} `}>
					Cookies can be used to find out the number of visitors to the site, save choices made on
					the site (e.g. language settings), track how the site is used (so-called click paths) and
					target and manage advertising (e.g. the same advertisements are not shown multiple times).
					However, we do not track the data of an individual visitor, but we collect visitor data
					based on e.g. behavior and geographical location.
				</div>{" "}
				<div className={`${classes.tosMainTitle_p} `}>
					With the help of cookies, we aim to improve the user experience on our pages and to bring
					advertising to the pages that interests the user. For example, you can see KoiramessuTV
					advertising when you visit other pages. This is possible with the help of cookies.
				</div>{" "}
				<div className={`${classes.tosMainTitle_p} `}>
					If the visitor visiting KoiramessuTV's website does not want KoiramessuTV's
					above-mentioned information through cookies, the use of cookies can be prohibited in the
					browser settings.
				</div>{" "}
				<div className={`${classes.tosTitle} font-500 `}>Marketing register statement</div>
				<div className={`${classes.tosMainTitle_p} `}>
					Combined register statement and information document
				</div>
				<div className={classes.padded}>
					<ol>
						<li>
							<div className={`${classes.tosTitle} font-300`}>Registrar</div>
							Suomen Kennelliitto - Finska Kennelklubben ry. (Y-tunnus: 0202155-0) Valimotie 17-19,
							00380 Helsinki
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>
								Contact information in matters concerning the processing of personal data
							</div>
							Suomen Kennelliitto - Finska Kennelklubben ry. (Y-tunnus: 0202155-0) Valimotie 17-19,
							00380 Helsinki
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>Register name</div>
							KoiramessuTV's customer, information and marketing register.
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>
								Legal basis and purpose of personal data processing
							</div>

							<ul style={{ display: "inline" }}>
								<div className={`${classes.tosMainTitle_p} `}>
									General requirements according to Section 8 of the Personal Data Act and Chapter 2
									of the EU Data Protection Regulation.
								</div>
								<div className={`${classes.tosMainTitle_p} `}>
									The purpose of processing personal data is to communicate about KoiramessuTV's
									service and new content.
								</div>
								<div className={`${classes.tosMainTitle_p} `}>
									The processing of the necessary personal data is a necessary condition for the
									fulfillment of the purpose.
								</div>
								<div className={`${classes.tosMainTitle_p} `}>
									The basis for processing personal data is consent.
								</div>
								<div className={`${classes.tosMainTitle_p} `}>
									The most typical areas of use of the data are:
								</div>

								<li>
									service development, direct marketing, donation requests, event invitations,
									digital marketing and distance sales, customer and opinion surveys.
								</li>
								<li>
									KoiramessuTV only stores information necessary for the operation of the service
									and the purposes for which the information is used, the processing of which has
									legal requirements. Information that has become unnecessary and information for
									which there is no longer any basis for processing will be anonymized or destroyed
									securely.
								</li>
								<li>
									Personal data may be processed by third parties as permitted by applicable
									legislation, including marketing, distance sales and market and opinion surveys.
									Third parties can be advertising agencies, printing houses or similar partners
									that support the fulfillment of the purpose of the register.
								</li>
								<li>
									KoiramessuTV has appropriate agreements with such third parties for the processing
									of personal data.
								</li>
							</ul>
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>Data content of the register </div>
							The following information can be processed in the marketing register:
							<ul>
								<li>Name information</li>
								<li>Contact information (email)</li>
								<li> Address source (through which the address entered the register)</li>
								<li>History of contacts</li>
							</ul>
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>Regular sources of information </div>
							<ul>
								<li>
									The personal data of the marketing register is collected through different
									channels from people who have given their contact information to Koiramessu TV
									(e.g. subscription to the newsletter, filling out a contest/raffle card).
								</li>
								<li>
									Providing the necessary information is a prerequisite for receiving marketing in
									the aforementioned cases.{" "}
								</li>
								<li>No automatic updates are made in the register.</li>
							</ul>
						</li>
						<li>
							{" "}
							<div className={`${classes.tosTitle} font-300`}>
								Regular disclosures of information
							</div>
							<ul>
								<li>
									{" "}
									The association does not hand over the information of those in its register to
									outsiders for purposes other than those mentioned in this register statement and
									to fulfill its statutory duties or exercise its rights without the express and
									informed consent of the person concerned.{" "}
								</li>
								<li>
									{" "}
									Personal data of registrants may be processed by partners involved in the
									implementation of Koiramessu TV's marketing and remote sales (e.g. a mailing
									company) or for possible customer or opinion research. Koiramessu TV has
									appropriate agreements with such third parties for the processing of personal data
								</li>
							</ul>
						</li>

						<li>
							{" "}
							<div className={`${classes.tosTitle} font-300`}>
								Data transfer outside the EU or the European Economic Area{" "}
							</div>
							Koiramessu TV does not hand over information contained in its stakeholder register to
							the EU or EEA outside.
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>
								Principles of registry protection{" "}
							</div>
							<ul>
								<li>
									The marketing register is maintained in electronic form in the Icareus Suite
									system and in small parts on the network disk as Excel files. Systems databases
									and the software is on the service provider's servers on the Internet, to which
									the workstations are connected user interface protected by user and password
									protection.
								</li>
								<li>
									Using the register requires a personal username and password, which the system
									forces you to change periodically. The right of use ends when the person moves
									away from the tasks for which he was granted the right of use. At the same time,
									the username is locked.
								</li>
								<li>
									Personal data is treated confidentially. The registrar has organized access
									control to its premises.
								</li>
							</ul>
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>
								Right of inspection and correction of data{" "}
							</div>
							<ul>
								<li>
									The registered person has the right to check the information about himself in
									Koiramessu TV's register and request the correction of incorrect information. The
									registered person can submit a request for inspection rights in person or in
									writing to the contact information mentioned in point 2. The inspection right is
									implemented within a week of submitting the request.
								</li>
								<li>
									The register does not contain information for which the right of inspection cannot
									be exercised. The registered person can present the correction of the information
									at any time to the person responsible for registry matters.
								</li>

								<li>
									Information can be corrected or supplemented by the controller or at the request
									of the data subject.
								</li>

								<li>
									The registered person has the right to demand restriction of data processing and
									to object to data processing.{" "}
								</li>

								<li>
									A person in Koiramessu TV's register has the right to prohibit the use of the
									information they provide for direct marketing purposes, etc., to change their
									consent regarding their publication, and to otherwise rely on their rights
									protected by the Personal Data Act. If the person does not want any mail or
									contact from Koiramessu TV, he can deny it by writing to the address mentioned in
									point 2.
								</li>

								<li>
									Koiramessu TV: deletes unnecessary personal data from the register at the request
									of the data subject.
								</li>
								<li>
									The registered person has the right to file a complaint with the data protection
									authority.
								</li>
							</ul>
						</li>

						<li>
							<div className={`${classes.tosTitle} font-300`}>Data Retention</div>
							The information is stored for the time being, as long as it is necessary. Information
							that has become unnecessary and information for which there is no longer any basis for
							processing will be securely disposed of.
						</li>
					</ol>
				</div>
			</ol>
		</div>
	);
};

export default PrivacyPolicyEn;
