import React, { useEffect, useState } from "react";
import * as classes from "../ChannelsMainPage.module.css";
import { getChannels } from "../../../scripts/dataHandlers";
import Loader from "react-loader-spinner";
import Channelsitem from "./Channelsitem";
import { useMyContext } from "../../../contexts/StateHolder";
import { useParams } from "react-router";
import { getImageByKey } from "../../../scripts/getImageByKey";
import * as classesVideos from "../Channel/ChannelVideos.module.css";
import Header from "../../../Shared/Header/Header";
export default function ChannelsMainPage() {
    const [channelsList, setChannelsList] = useState(null);

    const { key } = useMyContext();
    const { orgId } = useParams();
    useEffect(() => {
        const runFunction = async () => {
            let res;

            // res = await getChannels(organizationId);
            // res = await getChannels('69922');
            res = await getChannels(orgId, key);

            console.log(res, "res channeels");
            res.data.organization && setChannelsList(res.data.organization.subOrganizations);
        };
        key && runFunction();
    }, [orgId, key]);
    return channelsList && channelsList.length > 0 ? (
        <div className={classes.ChannelsMainPage}>
            <div className={classes.ChannelsMainPage__main}>
                {/* <div className={`${classes.ChannelsMainPage_Heading} font-700`}>
          All Channels
        </div> */}
                <Header title={"All Channels"} extraClassname />
                <div className={classes.ChannelsMainPage_primary}>
                    {channelsList.length > 0 ? (
                        channelsList.map((el) => <Channelsitem channel={el} organizationId={orgId} />)
                    ) : (
                        <div className={classesVideos.NoDataAvailable}>
                            <h2 className="font-700">No Channels...</h2>
                            <img src={getImageByKey("no_channels_available")} alt="No Channels Availabe" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) : (
        <div className="display-flex-center">
            {" "}
            <Loader type="TailSpin" color="#3aaa35" />
        </div>
    );
}
